import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    private excludedUrls: string[] = [
        '/users/login'
      ];

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = localStorage.getItem('jwtToken');

    const isExcluded = this.excludedUrls.some(url => req.url.includes(url));

    if (isExcluded) {
      // If the URL is excluded, bypass the interceptor logic and continue with the request
      return next.handle(req);
    }

    if (token) {
      // Clone the request and set the new header in one step.
      const authReq = req.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`
        }
      });
      return next.handle(authReq);
    }

    // Pass on the request if it does not have a token
    return next.handle(req);
  }
}
