import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { BarCode } from '../api/barcode';
import { environment } from 'src/environments/environment';

@Injectable()
export class BarCodesService {
    private baseUrl = environment.apiUrl;
    constructor(private http: HttpClient) { }

    getBarCodesForDeliveryOrder(deliveryOrderId: string) {
        return this.http.get<any>(`${this.baseUrl}/deliveryOrder/${deliveryOrderId}/barcodes`).pipe(
            map(res => res as any)
        );
    }

    uploadBarCodes(req: any) {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.http.put<any>(`${this.baseUrl}/barcode/scanUpload`,req,{ headers }).pipe(
            map(res => res as any)
        );
    }

    fetchByBarcodeId(barcodeId){
        return this.http.get<any>(`${this.baseUrl}/barcode/${barcodeId}`).pipe(
            map(res => res as any)
        );
    }
}