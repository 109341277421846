import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Weaver } from '../api/weaver';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ApiEndpoints } from '../api/api-endpoints';

@Injectable()
export class WeaverService {
    private baseUrl = environment.apiUrl;

    constructor(private http: HttpClient) { }

    getWeavers() {
        return this.http.get<any>(`${this.baseUrl}/weavers`).pipe(
            map(res => {
                return res as Weaver[]
            })
          );
    }

    addWeaver(req:any) {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.http.post<any>(`${this.baseUrl}/${ApiEndpoints.WEAVERS}`,req,{ headers }).pipe(
            map(res => res as any[])
        );
    }

    updateWeaver(req:any){
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.http.put<any>(`${this.baseUrl}/${ApiEndpoints.WEAVERS}/${req?.id}`,req,{ headers }).pipe(
            map(res => res as any[])
        );
    }

    deleteData(req:any, endpoint:ApiEndpoints, id:any) {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.http.delete<any>(`${this.baseUrl}/${endpoint}/${id}`,{headers}).pipe();
    }
}