export enum ApiEndpoints {
    COLOR = 'designs/colors',
    READ_PICK = 'designs/readpick',
    WEFT_DESIGN = 'designs/weft',
    WARP_DESIGN = 'designs/warp',
    INCHES_DESIGN = 'designs/inch',
    YARN_QUALITY = 'designs/yarnquality',
    WEAVERS = 'weavers',
    SIZERS = 'sizers',
    DELIVERY_ORDER = 'deliveryOrder',
    BARCODE = 'barcode',
    USERS = 'users'
    // Add more endpoints as needed
  }