import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { sizer } from '../api/sizer';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ApiEndpoints } from '../api/api-endpoints';

@Injectable()
export class SizerService {
    private baseUrl = environment.apiUrl;
    constructor(private http: HttpClient) { }

    getSizers() {
        return this.http.get<any>(`${this.baseUrl}/sizers`).pipe(
            map(res => {
                return res as sizer[]
            })
        );
    }

    postData(req:any, endpoint:ApiEndpoints) {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.http.post<any>(`${this.baseUrl}/${endpoint}`,req,{ headers }).pipe(
            map(res => res as any[])
        );
    }

    putData(req:any, endpoint:ApiEndpoints, id:any) {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.http.put<any>(`${this.baseUrl}/${endpoint}/${id}`,req,{ headers }).pipe(
            map(res => res as any[])
        );
    }

    deleteData(req:any, endpoint:ApiEndpoints, id:any) {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.http.delete<any>(`${this.baseUrl}/${endpoint}/${id}`,{headers}).pipe();
    }
}