import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ApiEndpoints } from '../api/api-endpoints';

@Injectable()
export class UserService {
    private baseUrl = environment.apiUrl;
    constructor(private http: HttpClient) { }

    getUsers() {
        return this.http.get<any>(`${this.baseUrl}/users`).pipe(
            map(res => {
                return res as any[]
            })
        );
    }

    login(req:any) {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.http.post<any>(`${this.baseUrl}/users/login`,req,{ headers }).pipe(
            map(res => res as any)
        );
    }

    postData(req:any, endpoint:ApiEndpoints) {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.http.post<any>(`${this.baseUrl}/${endpoint}`,req,{ headers }).pipe(
            map(res => res as any[])
        );
    }

    deleteData(req:any, endpoint:ApiEndpoints) {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.http.delete<any>(`${this.baseUrl}/${endpoint}/${req?.email}`,{headers}).pipe();
    }
}