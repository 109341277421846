import { NgModule } from '@angular/core';
import { CommonModule, HashLocationStrategy, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AppLayoutModule } from './layout/app.layout.module';
import { WeaverService } from './wf/service/weaverservice';
import { DeliveryOrderService } from './wf/service/deliveryorderservice';
import { NgxPrintModule } from 'ngx-print';
import { SizerService } from './wf/service/sizerservice';
import { DesignDetailService } from './wf/service/designdetailsservice';
import { BarCodesService } from './wf/service/fetchBarCodesService';
import { UserService } from './wf/service/userservice';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './wf/util/request.interceptor';
import { ResponseInterceptor } from './wf/util/response.interceptor';
import { LoadingInterceptor } from './wf/util/loading.interceptor';
import { SpinnerComponent } from './layout/spinner/spinner.component';

@NgModule({
    declarations: [AppComponent, SpinnerComponent],
    imports: [AppRoutingModule, AppLayoutModule, NgxPrintModule, CommonModule],
    providers: [
        { provide: LocationStrategy, useClass: PathLocationStrategy },
        {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: ResponseInterceptor, multi: true},
        { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },
        WeaverService, DeliveryOrderService, SizerService,
        DesignDetailService, BarCodesService, UserService
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
