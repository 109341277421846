<div class="layout-topbar">
    <div class="topbar-start">
        <button #menubutton class="p-link layout-menu-button layout-topbar-button" (click)="layoutService.onMenuToggle()">
            <i class="pi pi-bars"></i>
        </button>

        <a class="layout-topbar-logo" routerLink="">
            <img src="assets/layout/images/brand/main-logo-transparent.svg" alt="logo">
        </a>

        
    </div>

    <div class="topbar-end">
        <div #topbarmenu class="layout-topbar-menu" [ngClass]="{'layout-topbar-menu-mobile-active': layoutService.state.profileSidebarVisible}">
            <div class="layout-topbar-search" style="display: flex; margin-right: auto;">
                <p-dropdown 
                    [options]="filterCriteria" 
                    [(ngModel)]="selectedFilter" 
                    placeholder="Search By"
                    class="filter-dropdown mr-2"
                ></p-dropdown>
        
                <span class="block mt-2 md:mt-0 p-input-icon-right">            
                    <input pInputText type="text"  placeholder="Search..." [(ngModel)]="searchTerm" (keydown.enter)="onSearch()" class="w-full sm:w-auto"/>
                    <i (click)="onSearch()" class="pi pi-search"></i>
                </span>
    
            </div>
            <button *ngIf="showAdminPanel" class="p-link layout-topbar-button" [routerLink]="'/admin'">
                <i class="pi pi-cog"></i>
                <span>Admin Panel</span>
            </button>
            <button class="p-link layout-topbar-button" [routerLink]="'/logout'">
                <i class="pi pi-sign-out"></i>
                <span>Logout</span>
            </button>
        </div>
    </div>
     
    
    
    
</div>