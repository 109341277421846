import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import {jwtDecode} from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    const token = localStorage.getItem('jwtToken');

    if (token) {
      try {
        const decodedToken: any = jwtDecode(token);
        if (decodedToken?.isAdmin) { // Replace with the actual admin email(s)
          return true;
        } else {
          // Redirect to an unauthorized page or back to the login page
          this.router.navigate(['/']);
          return false;
        }
      } catch (error) {
        // Handle token decoding errors or invalid token
        this.router.navigate(['/']);
        return false;
      }
    } else {
      // No token, redirect to login
      this.router.navigate(['/']);
      return false;
    }
  }
}
