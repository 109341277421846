import { jwtDecode } from 'jwt-decode';
import * as FileSaver from 'file-saver';
import * as xlsx from 'xlsx';

export class Utils {
    static isAdmin(): boolean {
        let decodedToken;
        const token = localStorage.getItem('jwtToken');
        if (token) {
            decodedToken = jwtDecode(token);
            return decodedToken?.isAdmin;
        }
        return false;
    }

    static exportExcel(reportData, fileName) {
        const worksheet = xlsx.utils.json_to_sheet(reportData);
        Object.keys(worksheet).forEach((cell) => {
            const cellRef = xlsx.utils.decode_cell(cell);
            if (cell[0] !== '!') { // Skip metadata cells like !ref, !cols, etc.
                worksheet[cell].t = 's'; // Set cell type to 's' (string)
            }
        });

        const workbook = {
            Sheets: { data: worksheet },
            SheetNames: ['data'],
        };


        const excelBuffer: any = xlsx.write(workbook, {
            bookType: 'xlsx',
            type: 'array',
        });
        this.saveAsExcelFile(excelBuffer, fileName);
    }
    private static saveAsExcelFile(buffer: any, fileName: string) {
        let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        let EXCEL_EXTENSION = '.xlsx';
        const data: Blob = new Blob([buffer], {
            type: EXCEL_TYPE
        });
        FileSaver.saveAs(data, fileName + '_report_' + new Date().getTime() + EXCEL_EXTENSION);
    }
}
