import { Component, ViewEncapsulation } from '@angular/core';
import { LoadingService } from 'src/app/wf/service/loadingservice';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrl: './spinner.component.scss',
  encapsulation: ViewEncapsulation.ShadowDom
})
export class SpinnerComponent {
  constructor(public loader: LoadingService) { }
}
