import { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { LayoutService } from './service/app.layout.service';

@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html'
})
export class AppMenuComponent implements OnInit {

    model: any[] = [];

    constructor(public layoutService: LayoutService) { }

    ngOnInit() {
        this.model = [
            {
                items: [
                    { label: 'Dashboard', icon: 'pi pi-bolt', routerLink: ['/weavlog'] },
                    { label: 'Reports', icon: 'pi pi-book',
                        items: [
                            {
                                label: 'Open Order',
                                icon: 'pi pi-fw pi-file-export',
                                routerLink: ['/weavlog/report']
                            },
                            {
                                label: 'Receipt of Goods',
                                icon: 'pi pi-fw pi-file-export',
                                routerLink: ['/weavlog/yarnDebitReport']
                            }
                        ]
                    },
                    { label: 'Create Delivery Order', icon: 'pi pi-briefcase', routerLink: ['/weavlog/delivery-order'] },
                    { label: 'Weavers', icon: 'pi pi-users', routerLink: ['/weavlog/weaver-details'] },
                    { label: 'Sizers', icon: 'pi pi-users', routerLink: ['/weavlog/sizer-details'] },
                    { label: 'Weft Designs', icon: 'pi pi-tags', routerLink: ['/weavlog/design-details-weft'] },
                    { label: 'Warp Designs', icon: 'pi pi-tags', routerLink: ['/weavlog/design-details-warp'] },
                    { label: 'Colors', icon: 'pi  pi-palette', routerLink: ['/weavlog/product-details'] },
                    { label: 'Reed Pick', icon: 'pi pi-link', routerLink: ['/weavlog/read-pick'] },
                    { label: 'Inches Design', icon: 'pi pi-ticket', routerLink: ['/weavlog/design-details-inch']},
                    { label: 'Yarn Count', icon: 'pi pi-link', routerLink: ['/weavlog/yarn-quality']},
                    { label: 'Barcode Details', icon: 'pi pi-qrcode', routerLink: ['/weavlog/barcode']},

                ]
            },
            
        ]
    }
}
