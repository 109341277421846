import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { AppLayoutComponent } from "./layout/app.layout.component";
import { AuthGuard } from './wf/util/auth.guard';

@NgModule({
    imports: [
        RouterModule.forRoot([
            {
                path: 'weavlog', component: AppLayoutComponent,
                children: [                    
                    { path: '', loadChildren: () => import('./wf/component/weavlog/weavlog.module').then(m => m.WeavLogModule)}
                ]
            },
            { path: '', loadChildren: () => import('./wf/component/auth/auth.module').then(m => m.AuthModule) },
            { path: 'admin', component: AppLayoutComponent, canActivate:[AuthGuard],
                children:[
                    {path: '', loadChildren: () => import('./wf/component/admin/admin.module').then(m => m.AdminModule) }
                ]
            }
        ], { scrollPositionRestoration: 'enabled', anchorScrolling: 'enabled', onSameUrlNavigation: 'reload' })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
