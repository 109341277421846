import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable()
export class DeliveryOrderService {
    private baseUrl = environment.apiUrl;
    constructor(private http: HttpClient) { }

    getDeliveryOrderDetails(startDate,endDate) {
        let params = new HttpParams().set('startDate', startDate).set('endDate', endDate);
        return this.http.get<any>(`${this.baseUrl}/deliveryOrder`,{params}).pipe(
            map(res => res as any[])
        );
    }

    getDeliveryOrderById(id: any) {
        return this.http.get<any>(`${this.baseUrl}/deliveryOrder/${id}`).pipe(
            map(res => res as any)
        );
    }

    addDeliveryOrder(req:any){
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.http.post<any>(`${this.baseUrl}/deliveryOrder`,req,{ headers }).pipe(
            map(res => res as any[])
        );
    }

    updateDeliveryOrder(req:any, id:any){
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.http.patch<any>(`${this.baseUrl}/deliveryOrder/${id}`,req,{ headers }).pipe(
            map(res => res as any)
        );
    }

    getBarcodesForOrderId(id:any){
        return this.http.get<any>(`${this.baseUrl}/barcode/${id}`).pipe(
            map(res => res as any[])
        );
    }

    deleteDeliveryOrder(id:any){
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.http.delete<any>(`${this.baseUrl}/deliveryOrder/${id}`,{ headers }).pipe(
            map(res => res as any)
        );
    }
}