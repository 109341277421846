import { Component, ElementRef, ViewChild } from '@angular/core';
import { MenuItem, SelectItem } from 'primeng/api';
import { LayoutService } from "./service/app.layout.service";
import { Router } from '@angular/router';
import { Utils } from '../wf/util/util';

@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html'
})
export class AppTopBarComponent {

    items!: MenuItem[];
    showAdminPanel: boolean = false;
    searchTerm:string;
    selectedFilter:string = "barcodeId";
    filterCriteria:SelectItem[];

    @ViewChild('menubutton') menuButton!: ElementRef;

    @ViewChild('topbarmenubutton') topbarMenuButton!: ElementRef;

    @ViewChild('topbarmenu') menu!: ElementRef;

    constructor(public layoutService: LayoutService, private router:Router) { }

    ngOnInit() {
        this.showAdminPanel = Utils.isAdmin();
        this.filterCriteria=[{label:"Barcode", value:"barcodeId"},{label:"Order Id", value:"orderId"}];
    }
    onOptionSelect(event) {
        if (event.value === 'admin') {
          this.router.navigate(['/admin']);
        } else if (event.value === 'logout') {
          // Navigate to Logout
          this.router.navigate(['/logout']);
        }
    }

    onSearch() {
      if(this.selectedFilter === 'barcodeId'){
        this.router.navigate(['weavlog/barcode', this.searchTerm]);
      }else{
        this.router.navigate(['weavlog/delivery-order', this.searchTerm]);
      }
    }
}
