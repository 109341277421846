import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ApiEndpoints } from '../api/api-endpoints';

@Injectable()
export class DesignDetailService {
    private baseUrl = environment.apiUrl;
    constructor(private http: HttpClient) { }


    postData(req:any, endpoint:ApiEndpoints) {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.http.post<any>(`${this.baseUrl}/${endpoint}`,req,{ headers }).pipe(
            map(res => res as any[])
        );
    }

    putData(req:any, endpoint:ApiEndpoints, id:any) {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.http.put<any>(`${this.baseUrl}/${endpoint}/${id}`,req,{ headers }).pipe(
            map(res => res as any[])
        );
    }

    patchData(req:any, endpoint:ApiEndpoints, id:any) {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.http.patch<any>(`${this.baseUrl}/${endpoint}/${id}`,req,{ headers }).pipe(
            map(res => res as any[])
        );
    }

    deleteData(req:any, endpoint:ApiEndpoints, id:any) {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.http.delete<any>(`${this.baseUrl}/${endpoint}/${id}`,{headers}).pipe();
    }

    getAllDesignDetail() {
        return this.http.get<any>(`${this.baseUrl}/designDetails`).pipe(
            map(res => res as any[])
          );
    }

    getColors() {
        return this.http.get<any>(`${this.baseUrl}/${ApiEndpoints.COLOR}`).pipe(
            map(res => res as any[])
          );
    }

    getReadPick() {
        return this.http.get<any>(`${this.baseUrl}/designs/readpick`).pipe(
            map(res => res as any[])
          );
    }

    getWeftDesigns() {
        return this.http.get<any>(`${this.baseUrl}/designs/weft`).pipe(
            map(res => {
                return res as any[]
            })
          );
    }

    getWarpDesigns() {
        return this.http.get<any>(`${this.baseUrl}/designs/warp`).pipe(
            map(res => res as any[])
          );
    }

    getinchesDesigns() {
        return this.http.get<any>(`${this.baseUrl}/designs/inch`).pipe(
            map(res => res as any[])
          );
    }

    getYarnQuality() {
        return this.http.get<any>(`${this.baseUrl}/designs/yarnquality`).pipe(
            map(res => res as any[])
          );
    }

    
}